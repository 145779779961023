import {React , useContext} from 'react'
import "../styles/language.css"

import { HomeContext } from "../Context/HomeContext"

import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import 'bootstrap/dist/css/bootstrap.min.css';

const Language = () => {

  const {languages, _handleLanguage, selectedLanguages} = useContext(HomeContext)

  return (
    <>
    <DropdownButton id="dropdown-basic-button" className="language-switch" title={selectedLanguages}>
      {languages.map((language, index) => (
                <Dropdown.Item key={index} onClick={() => _handleLanguage(language)} > 
                <div className="language-group">
                <img src={language.icon} className="language-icon" alt="language_icon" />
                 {language.language}
                </div>
                </Dropdown.Item>
      ))}
    </DropdownButton>
    </>
  )
}

export default Language