import React from 'react'
import "../styles/footer.css"

import Language from "../components/Language"

const Footer = () => {
  return (
    <div className="footer-container">

      <div className="footer-main">

      <div className="logo-menu">
      <img src="GetBodrum-Logomark.png" className="footer-logo" alt="logo" />
      </div>
     
      <div className="footer-menu">
      <p> Get Teknoloji Turizm Organizasyon Seyahat Ticaret Limited Şirketi | Duns Number : 751128790 </p>
      <p> Küçük Çiğli Mah. 8783 Sk. No:1 İç Kapı No:12 </p>
      <p> Çiğli / İzmir </p>
      <p> 0 532 376 4848 </p>
      

      </div>
      
      <div className="footer-social">
      
      <div className="footer-social-layout">
      <a href="https://www.facebook.com/profile.php?id=61551782951121" target="_blank" rel="noreferrer" > <img src="facebook.png" className="footer-icon" alt="logo" />  </a>

      <a href="https://www.twitter.com/getbodrum" target="_blank" rel="noreferrer" >  <img src="twitter.png" className="footer-icon" alt="logo" />  </a>

      <a href="https://www.instagram.com/getbodrum/" target="_blank" rel="noreferrer" > <img src="instagram.png" className="footer-icon" alt="logo" />  </a>

      <a href="https://www.tiktok.com/@getbodrum" target="_blank" rel="noreferrer" > <img src="tiktok.png" className="footer-icon" alt="logo" />  </a>
      </div>
      <Language />

      </div>

      </div>
     

    </div>
  )
}

export default Footer