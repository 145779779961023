import { React, useContext , useState } from 'react'
import "../styles/navbar.css"
import { HomeContext } from '../Context/HomeContext'
import { Link } from 'react-router-dom';


const Navbar = () => {

  const { selectedLanguages } = useContext(HomeContext)

  
  const [menuClass, setMenuClass] = useState("menu hidden")
  const [isMenuClicked, setIsMenuClicked] = useState(false);

  const updateMenu = () => {
    setIsMenuClicked(!isMenuClicked); 
    if (isMenuClicked) {
      setMenuClass("menu hidden");
    } else {
      setMenuClass("menu");
    }
  };


  return (
    <div className="navbar-container">

      <div className="navbar-left">
        <a href="/">  <img src="GetBodrum-Logo.png" className="bodrum-logo" alt="logo" /> </a>

      </div>

      <div className="navbar-right">

        <ul className="navmenu-main">
          <li className="navmenu-item">
            <Link to="/">{selectedLanguages === "English" ? "Home" : "Anasayfa"}</Link>
          </li>
          <li className="navmenu-item">
            <Link to="/contact">{selectedLanguages === "English" ? "Contact" : "İletişim"}</Link>
          </li>
          <li className="navmenu-item">
            <Link to="/privacy-policy">{selectedLanguages === "English" ? "Privacy Policy" : "Gizlilik Politikası"}</Link>
          </li>
        </ul>


        <div className="hamburger-main" onClick={updateMenu}>
          <div className="hamburger-item"></div>
          <div className="hamburger-item"></div>
          <div className="hamburger-item"></div>
        </div>

        <div className={menuClass}>
        <ul className="menuClass-2">
          <li className="menuClass-3">
            <Link to="/">{selectedLanguages === "English" ? "Home" : "Anasayfa"}</Link>
          </li>
          <li className="menuClass-3">
            <Link to="/contact">{selectedLanguages === "English" ? "Contact" : "İletişim"}</Link>
          </li>
          <li className="menuClass-3">
            <Link to="/privacy-policy">{selectedLanguages === "English" ? "Privacy Policy" : "Gizlilik Politikası"}</Link>
          </li>
        </ul>
        </div>

      </div>

    </div>
  )
}

export default Navbar



