import React from 'react'
import "../styles/contact.css"
import Navbar from "../components/Navbar"
import Footer from "../components/Footer"




const Contact = () => {
  return (
    <div className="contact-container">
      <Navbar />
      <div className="contact-layout-main">
        <div className="resp-contact">
          <div className="contact-main">
            <img src="contact-bg.jpg" className="contact-bg" alt="logo" />


            <h1 className="contact-header"> Contact US  </h1>

            <div className="table-cont">

              <div className="table-cont-left">
                <p className="contact-info-2"> Get Teknoloji Turizm Organizasyon Seyahat Ticaret Limited Şirketi  Duns Number : 751128790 </p>
                <div className="footer-social-2">
                  <a href="https://www.facebook.com/profile.php?id=61551782951121" target="_blank" rel="noopener noreferrer">
                    <img src="facebook2.png" className="footer-icon-2" alt="logo" />
                  </a>

                  <a href="https://www.twitter.com/getbodrum" target="_blank" rel="noopener noreferrer">
                    <img src="twitter2.png" className="footer-icon-2" alt="logo" />
                  </a>

                  <a href="https://www.instagram.com/getbodrum/" target="_blank" rel="noopener noreferrer">
                    <img src="instagram2.png" className="footer-icon-2" alt="logo" />
                  </a>

                  <a href="https://www.tiktok.com/@getbodrum" target="_blank" rel="noopener noreferrer">
                    <img src="tiktok2.png" className="footer-icon-2" alt="logo" />
                  </a>
                </div>
              </div>


              <div className="contact-table">

                <div className="table-colm">
                  <img src="location.png" className="contact-icon" alt="logo" />
                  <p className="contact-info"> Küçük Çiğli Mah. 8783 Sk. No:1 İç Kapı No:12 Çiğli / İzmir </p>
                </div>


                <div className="table-colm">
                  <img src="phone-call.png" className="contact-icon" alt="logo" />
                  <p className="contact-info"> 0 532 376 4848</p>
                </div>


                <div className="table-colm">
                  <img src="mail.png" className="contact-icon" alt="logo" />
                  <p className="contact-info"> info@getbodrum.com </p>
                </div>

              </div>

            </div>
          </div>


        </div>

      </div>
      <Footer />
    </div>

  )
}

export default Contact