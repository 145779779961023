import { createContext , useState } from "react";

export const HomeContext = createContext();

const HomeContextProvider = (props) => {

    const [selectedLanguages, setSelectedLanguages] = useState("English");
    
    /* Languages States and Functions */
    const [languages, setLanguages] = useState([
        {
          "language" : "Türkçe",
          "icon" : "turkey_555560.svg"
        },
        {
          "language" : "English",
          "icon" : "united-kingdom_555417.svg"
        }
      ])

      const _handleLanguage = (language) => {
        setSelectedLanguages(language.language)
      }

    

    return (
        <HomeContext.Provider value = {{languages, setLanguages, _handleLanguage, selectedLanguages}}>
            {props.children}
        </HomeContext.Provider>
    )
}

export default HomeContextProvider;