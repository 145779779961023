import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Contact from "./pages/Contact"
import Privacy from "./pages/Privacy"
import HomeContextProvider from './Context/HomeContext';




const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
    
    <HomeContextProvider>

    <Routes> 
      <Route path="/" element={<Home />}></Route>
      <Route path="/contact" element={<Contact />}></Route>
      <Route path="/privacy-policy" element={<Privacy />}></Route>
    </Routes>

    
    </HomeContextProvider>
    
    
    </BrowserRouter>
);


