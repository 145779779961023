import {React , useContext} from 'react'
import "../styles/Home.css"
import Footer from "../components/Footer"
import Navbar from "../components/Navbar"
import { HomeContext } from "../Context/HomeContext"

const Home = () => {

  const {selectedLanguages} = useContext(HomeContext)
  
  return (
    <div className="layout-main">
     <Navbar />

    <div className="home-main">
    
    <div className="top-banner">
      <h1 className="banner-header"> {selectedLanguages === "English" ? "Your Dream App Is Here" : "Rüya gibi bir uygulama burada" }  </h1>
      <p className="banner-text"> {selectedLanguages === "English" ? "It's possible to reach every beautiful place in Bodrum!" : "Bodrum'un bütün güzel yerlerine ulaşmak artık mümkün!"} </p>
      <button type="text" className="banner-button"> {selectedLanguages === "English" ? "Get the app" : "Uygulamayı İndir"} </button>
    </div>


    <div className="about-main"> 
    <h1 className="headers">  {selectedLanguages === "English" ? "About US" : "Hakkımızda"} </h1>
    <p className="text">
  {selectedLanguages === "English"
    ? (
      <>
        It is a next-generation application that you can use to discover the beauties you desire in Bodrum.
        <br />
        We claim, 'We will meet all your transportation-related needs in Bodrum!'
      </>
    )
    : (
      <>
        Bodrum'da arzuladığınız güzellikleri keşfetmek için kullanabileceğiniz bir yeni nesil uygulamadır.
        <br />
        İddia ediyoruz Bodrum'daki tüm ulaşım ihtiyaçlarınızı karşılayacağız.
      </>
    )
  }
</p>
    <div className="about-bottom">
      
      <div className="bottom-cont">
      <div className="bottom-main">
      <img src="person.png" className="bottom-icon-1" alt="logo" />
      </div>
      <div> <p className="bottom-text"> {selectedLanguages === "English" ? "+100 Drivers" : "+100 Sürücü"}  </p></div>
      </div>

      <div className="bottom-cont">
      <div className="bottom-main">
      <img src="group.png" className="bottom-icon-2" alt="logo" />
      </div>
      <div> <p className="bottom-text"> {selectedLanguages === "English" ? "+1000 Passengers" : "+1000 Yolcu"} </p></div>
      </div>

      <div className="bottom-cont">
      <div className="bottom-main">
      <img src="download.png" className="bottom-icon-2" alt="logo" />
      </div>
      <div> <p className="bottom-text"> {selectedLanguages === "English" ? "+5000 Download" : "+5000 İndirme"}</p></div>
      </div>
      
     
    </div>
    
    
    </div>

    <div className="download-main"> 
    <h1 className="headers-2"> {selectedLanguages === "English" ? "Download GetBodrum!" : "GetBodrum'u İndir!"} </h1>
    <div className="download-container">

    <div className="download-left">

    <p className={selectedLanguages === "English" ? "download-header" : "download-header-2"}> {selectedLanguages === "English" ? "Request in seconds, ride in minutes." : "Saniyeler için talep et, dakikalar içinde yolculuğuna başla"} </p>
    <p className="download-text"> {selectedLanguages === "English" ? "You can access all the beauties of Bodrum by downloading the application for free now!" : "Şimdi uygulamayı ücretsiz indirerek Bodrum'un tüm güzelliklerine erişebilirsin!"} </p>
    <button type="text" className="button-download"> {selectedLanguages === "English" ? "Get the app" : "Uygulamayı İndir"} </button>

    </div>
    <div className="download-right"> 
    <img src="getBodrum-mobile.png" className="gb-mobile" alt="logo" />
    <img src="qr-code.png" className="gb-qr" alt="logo" />
    </div>

    </div>
  

    </div>

   
    </div>
    <Footer />
    </div>
  )
}

export default Home