// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.language-switch {
    width: 100%;
    display: flex;
    justify-content: center; 
}

.language-group {
    display: flex;
    gap: 10px;
    align-items: center;
}

.language-icon {
    width: 20px;
}


.btn-primary {
    border: none !important;
    background-color: transparent !important;
    font-family: 'Poppins', sans-serif;
    color: white !important;
    padding: 0px 0px 0px 0px !important;
    font-size: 14px !important;
    font-weight: 300 !important;
}

@media only screen and (max-width: 420px) {
    .language-switch {
        width: 50%;
        display: flex;
        justify-content: center;  
    }
}  `, "",{"version":3,"sources":["webpack://./src/styles/language.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,uBAAuB;AAC3B;;AAEA;IACI,aAAa;IACb,SAAS;IACT,mBAAmB;AACvB;;AAEA;IACI,WAAW;AACf;;;AAGA;IACI,uBAAuB;IACvB,wCAAwC;IACxC,kCAAkC;IAClC,uBAAuB;IACvB,mCAAmC;IACnC,0BAA0B;IAC1B,2BAA2B;AAC/B;;AAEA;IACI;QACI,UAAU;QACV,aAAa;QACb,uBAAuB;IAC3B;AACJ","sourcesContent":[".language-switch {\n    width: 100%;\n    display: flex;\n    justify-content: center; \n}\n\n.language-group {\n    display: flex;\n    gap: 10px;\n    align-items: center;\n}\n\n.language-icon {\n    width: 20px;\n}\n\n\n.btn-primary {\n    border: none !important;\n    background-color: transparent !important;\n    font-family: 'Poppins', sans-serif;\n    color: white !important;\n    padding: 0px 0px 0px 0px !important;\n    font-size: 14px !important;\n    font-weight: 300 !important;\n}\n\n@media only screen and (max-width: 420px) {\n    .language-switch {\n        width: 50%;\n        display: flex;\n        justify-content: center;  \n    }\n}  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
